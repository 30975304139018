import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
  } from "react-router-dom";
  import { Navbar,Nav,NavDropdown,Form,FormControl,Button } from 'react-bootstrap'


class BootstrapNavbar extends React.Component{

    render(){
        return(
            <div>
                <div>
                    <div className="col-md-12">
                        <Router>
                        <nav class="navbar navbar-expand-md sticky-top navbar-light bg-light" sticky="top">
                        <img style = {{ marginleft:"100px",width:'205px', height:'59px',maxWidth:'100%', margin:'0', padding:'5px 0px 10px 0px'}} src="/assets/img/appicon.png" className="img-fluid" alt="" /> 
                            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
                                <span class="navbar-toggler-icon"></span>
                            </button>
                            <div class="collapse navbar-collapse" id="navbarNav">
                                <ul class="navbar-nav ml-auto">
                               
                                    <li class="nav-item active">
                                        <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#aboutus">About Us</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#portfolio">Portfolio</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#ourteam">Our Team</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" href="#contact">Contact Us</a>
                                    </li>
                                    <li class="nav-item">
                                        <p style={{color:'gray'}}>Chat with us<img  href='https://wa.me/+919820420530'  src="assets/img/wp.png" style={{width:'45px',height:'15xpx'}} class="img-fluid" alt=""/></p>
                                        
                                    </li>
                                </ul>
                              
                            </div>
                        </nav>


                            <br />
                            <Switch>
            
                            </Switch>
                        </Router>
                    </div>
                </div>
            </div>
        )  
    }
}

export default BootstrapNavbar;