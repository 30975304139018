import React, { Component } from "react";
// import Users from "./Users";
// import Leftside from "./Leftside";
// import Rightside from "./Rightside";
import Testmonials from './Testmonials'
import BootstrapNavbar from './Header'
import ScrollToTop from "react-scroll-to-top"
import BootstrapCarousel from './Testimonialsone'

class Maincontent extends Component {
  formatName(user) {
    return user.firstName + " " + user.lastName;
  }

  render() {
    return (
      <React.Fragment>
      <BootstrapNavbar/>

               
                  
          
         <BootstrapCarousel/>
         
          <main>            
         

           
            <section id="facts" className="facts">
              <div className="container">

                <div className="section-title">
                  <h2 style={{marginRight:'1000px'}}>Capabilities</h2>
                  {/* <p>We have 15 trade centers and affiliation all over India. Below mentioned are our achievements based on empirical data from Google.</p> */}
                </div>

                <div className="row no-gutters">

                  <div className="col-lg-4 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" style = {{marginRight:'55px'}}>
                    <div className="count-box">
                      <i class="icofont-chart-histogram"></i>
                      <span data-toggle="counter-up">1Million+</span>
                      <p><strong>Meters Manufactured Every Month</strong></p>
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="100" style = {{marginRight:'50px'}}>
                    <div className="count-box">
                      <i className="icofont-simple-smile"></i>
                      <span data-toggle="counter-up" >1000+</span>
                      <p><strong>Happy Clients</strong></p>
                    </div>
                  </div>

                  <div className="col-lg-3   col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="200">
                    <div className="count-box">
                    <i class="icofont-jacket"></i>
                      <span data-toggle="counter-up">1000+</span>
                      <p><strong>Designs Of Readily Available Stock</strong></p>
                    </div>
                  </div>

                  {/* <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch" data-aos="fade-up" data-aos-delay="300">
                    <div className="count-box">
                      <i className="icofont-users-alt-5"></i>
                      <span data-toggle="counter-up">50+</span>
                      <p><strong>Hard Workers</strong></p>
                    </div>
                  </div> */}

                </div>

              </div>
            </section>

       
            <ScrollToTop smooth style = {{backgroundColor:'dark', color:'white'}} />
            
            <section id="aboutus" className="about">
              
            <div class="container">

<div>
 <section id="about">
  <div class="container">



    <div class="section-title">
      <div><h3>About Us</h3></div>
    </div>
      <p>
        <i>
        Mayka – a name synonymous with the Indian domestic market has been supplying natural fabrics to the
        textile and apparel industry from its offices in Mumbai - the traditional heart of India's textile
        business since 1975.
        <br/><br/>

        Mayka comprises of two independent companies which are built on a reputation of integrity and quality:
        Mayka Syntex &amp; Mayka Lifestyle.
        <br/><br/>
        Since its inception, Mayka has evolved considerably from initially selling bottom weight fabrics and
        then moving over to the men’s shirting segment in 2001 and has over the last few years added women’s
        fabrics to its portfolio.
        <br/><br/>
        It has been a cornerstone in India’s textile market, selling fabric to regional &amp; national brands,
        wholesalers, exporters and corporates. It exports fabric as well to several countries.</i></p>
    </div>

  </section>

  </div>
</div>


            </section>
            

            
            {/* <section id="resume" className="resume">
              <div className="container">

                <div className="section-title">
                  <h2>Our History</h2>
                  <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                </div>

                <div className="row">
                  <div className="col-lg-6" data-aos="fade-up">
                    <h3 className="resume-title">Sumary</h3>
                    <div className="resume-item pb-0">
                      <h4>Mayka Fabrics</h4>
                      <p><em>Innovative and deadline-driven Graphic Designer with 3+ years of experience designing and developing user-centered digital/print marketing material from initial concept to final, polished deliverable.</em></p>
                      <ul>
                        <li>Portland par 127,Orlando, FL</li>
                        <li>(123) 456-7891</li>
                        <li>alice.barkley@example.com</li>
                      </ul>
                    </div>

                    <h3 className="resume-title">Education</h3>
                    <div className="resume-item">
                      <h4>Master of Fine Arts &amp; Graphic Design</h4>
                      <h5>2015 - 2016</h5>
                      <p><em>Rochester Institute of Technology, Rochester, NY</em></p>
                      <p>Qui deserunt veniam. Et sed aliquam labore tempore sed quisquam iusto autem sit. Ea vero voluptatum qui ut dignissimos deleniti nerada porti sand markend</p>
                    </div>
                    <div className="resume-item">
                      <h4>Bachelor of Fine Arts &amp; Graphic Design</h4>
                      <h5>2010 - 2014</h5>
                      <p><em>Rochester Institute of Technology, Rochester, NY</em></p>
                      <p>Quia nobis sequi est occaecati aut. Repudiandae et iusto quae reiciendis et quis Eius vel ratione eius unde vitae rerum voluptates asperiores voluptatem Earum molestiae consequatur neque etlon sader mart dila</p>
                    </div>
                  </div>
                  <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                    <h3 className="resume-title">Professional Experience</h3>
                    <div className="resume-item">
                      <h4>Senior graphic design specialist</h4>
                      <h5>2019 - Present</h5>
                      <p><em>Experion, New York, NY </em></p>
                      <ul>
                        <li>Lead in the design, development, and implementation of the graphic, layout, and production communication materials</li>
                        <li>Delegate tasks to the 7 members of the design team and provide counsel on all aspects of the project. </li>
                        <li>Supervise the assessment of all graphic materials in order to ensure quality and accuracy of the design</li>
                        <li>Oversee the efficient use of production project budgets ranging from $2,000 - $25,000</li>
                      </ul>
                    </div>
                    <div className="resume-item">
                      <h4>Graphic design specialist</h4>
                      <h5>2017 - 2018</h5>
                      <p><em>Stepping Stone Advertising, New York, NY</em></p>
                      <ul>
                        <li>Developed numerous marketing programs (logos, brochures,infographics, presentations, and advertisements).</li>
                        <li>Managed up to 5 projects or tasks at a given time while under pressure</li>
                        <li>Recommended and consulted with clients on the most appropriate graphic design</li>
                        <li>Created 4+ design presentations and proposals a month for clients and account managers</li>
                      </ul>
                    </div>
                  </div>
                </div>

              </div>
            </section> */}

            
            <section id="portfolio" className="portfolio section-bg">
              <div className="container">

                <div className="section-title">
                  <h2 style={{marginRight:'1000px'}}> Portfolio</h2>
                  
                </div>

                <div className="row" data-aos="fade-up">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <ul id="portfolio-flters">
                     
                     
                    </ul>
                  </div>
                </div>

                <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100">

                  <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                    <div className="portfolio-wrap">
                      <img style={{marginLeft : "30px",width:"300px",height:"300px"}} src="/assets/img/portfolio/1.jpg" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                     
                       
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <div className="portfolio-wrap">
                      <img style={{marginLeft : "30px",width:"300px",height:"300px"}} src="/assets/img/portfolio/2.jpg" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                        
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                    <div className="portfolio-wrap">
                      <img style={{marginLeft : "30px",width:"300px",height:"300px"}} src="/assets/img/portfolio/3.jpg" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                        
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                    <div className="portfolio-wrap">
                      <img style={{marginLeft : "30px",width:"300px",height:"300px"}} src="/assets/img/portfolio/4.jpg" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                      
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <div className="portfolio-wrap">
                      <img style={{marginLeft : "30px",width:"300px",height:"300px"}} src="/assets/img/portfolio/5.jpg" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                       
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                    <div className="portfolio-wrap">
                      <img style={{marginLeft : "30px",width:"300px",height:"300px"}}  src="/assets/img/portfolio/6.jpg" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                       
                       
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                    <div className="portfolio-wrap">
                      <img style={{marginLeft : "30px",width:"300px",height:"300px"}}  src="/assets/img/portfolio/7.jpg" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                      
                      
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                    <div className="portfolio-wrap">
                      <img style={{marginLeft : "30px",width:"300px",height:"300px"}}  src="/assets/img/portfolio/8.jpg" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                        
                     
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <div className="portfolio-wrap">
                      <img style={{marginLeft : "30px",width:"300px",height:"300px"}}  src="/assets/img/portfolio/9.jpg" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                       
                      
                      </div>
                    </div>
                  </div>

                </div>

              </div>
            </section>

            
            {/* <section id="services" className="services">
              <div className="container">

                <div className="section-title">
                  <h2 style={{marginRight:'1000px'}}>Services</h2>
                  <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
                    <div className="icon"><i className="icofont-computer"></i></div>
                    <h4 className="title"><a href="/#">Lorem Ipsum</a></h4>
                    <p className="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p>
                  </div>
                  <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                    <div className="icon"><i className="icofont-chart-bar-graph"></i></div>
                    <h4 className="title"><a href="/#">Dolor Sitema</a></h4>
                    <p className="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p>
                  </div>
                  <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                    <div className="icon"><i className="icofont-earth"></i></div>
                    <h4 className="title"><a href="/#">Sed ut perspiciatis</a></h4>
                    <p className="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p>
                  </div>
                  <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
                    <div className="icon"><i className="icofont-image"></i></div>
                    <h4 className="title"><a href="/#">Magni Dolores</a></h4>
                    <p className="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                  </div>
                  <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
                    <div className="icon"><i className="icofont-settings"></i></div>
                    <h4 className="title"><a href="/#">Nemo Enim</a></h4>
                    <p className="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p>
                  </div>
                  <div className="col-lg-4 col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
                    <div className="icon"><i className="icofont-tasks-alt"></i></div>
                    <h4 className="title"><a href="/#">Eiusmod Tempor</a></h4>
                    <p className="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p>
                  </div>
                </div>

              </div>
            </section> */}

            
            <section id="testimonials" className="testimonials section-bg">
            <div className="section-title">
            <div className="container">
                  <h2 style={{marginRight:'1000px'}}>Testimonials</h2>
                 
                  </div>
            </div>
            <Testmonials/>
            

                

            
            </section>
            <section id="ourteam" className="portfolio section-bg">
              <div className="container">

                <div className="section-title">
                  <h2 > Our Leadership Team</h2>
                  {/* <p>Meet our team.</p> */}
                </div>

                <div className="row" data-aos="fade-up">
                  <div className="col-lg-12 d-flex justify-content-center">
                    <ul id="portfolio-flters">
                      {/* <li data-filter="*" className="filter-active">Management</li> */}
                      {/* <li data-filter=".filter-app"></li> */}
                     
                    </ul>
                  </div>
                </div>

                <div className="row portfolio-container" data-aos="fade-up" data-aos-delay="100">
                  
                  <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                    <div className="portfolio-wrap">
                      
                      <img src="/assets/img/portfolio/jayanti.png" className="img-fluid" alt=""></img>
                      <div className="portfolio-links">
                       
                       
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <div className="portfolio-wrap">
                      <img src="/assets/img/portfolio/amit.png" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                        
                       
                      </div>
                    </div>
                  </div>



                  <div className="col-lg-4 col-md-6 portfolio-item filter-app">
                    <div className="portfolio-wrap">
                      <img src="/assets/img/portfolio/hardik.png" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                       
                       
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-web">
                    <div className="portfolio-wrap">
                      <img src="/assets/img/portfolio/samay.png" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                        
                      
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                    <div className="portfolio-wrap">
                      <img src="/assets/img/portfolio/nirav.png" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                      
                    
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 portfolio-item filter-card">
                    <div className="portfolio-wrap">
                      <img src="/assets/img/portfolio/mayank.png" className="img-fluid" alt="" />
                      <div className="portfolio-links">
                      
                    
                      </div>
                    </div>
                  </div>

                 
                </div>

              </div>
            </section>

          
            <section id="contact" className="contact">
              <div className="container">
             
                <div className="section-title">
                  <h2 style={{marginRight:'1000px'}}>Contact</h2>

                  
                 
                </div>

                <div  data-aos="fade-in">

                  <div className=" d-flex align-items-stretch">
                    
                    <div className="info">
             
                      <div class="row">

                  <div class="col-lg-6 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up">
                    <div class="info-box" style={{backgroundColor:"#fff"}}>
                      <i style={{marginLeft:'125px'}}  class="bx bx-map"></i>
                      <br/>
                      <br/>
                      <h2>Our Address</h2>
                      <p style={{marginRight:'60px',textAlign:'center'}} ><strong>Mayka Syntex : </strong> Mayka Syntex, 52, Kewal Industrial Estate, Senapati Bapat Marg, Lower Parel, Mumbai-400 013</p>
                      <br/>
                      <p style={{marginRight:'60px',textAlign:'center'}}><strong>Mayka Lifestyle : </strong> Mayka Lifestyle, 37, Ground Floor, Kewal Industrial Estate, Senapati
                        Bapat Marg,
                        Lower Parel, Mumbai - 400 013. </p>
                    </div>
                  </div>

                  <div class="col-lg-3 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                    <div class="info-box " style={{backgroundColor:"#fff"}}>
                      <i style={{marginLeft:'125px'}} class="bx bx-phone-call"></i>
                      <br/>
                      <br/>
                      <h2>Call Us</h2>
                      <strong style={{marginLeft:"50px",marginRight:'60px',textAlign:'center'}}>
                        Mayka Syntex :
                      </strong>
                      <p style={{marginRight:'60px',textAlign:'center'}}>022 62495555</p>
                      <br/>
                      <strong style={{marginLeft:"50px",marginRight:'60px',textAlign:'center'}}>
                        Mayka Lifestyle :
                      </strong>
                      <br/>
                      <p style={{marginRight:'60px',textAlign:'center'}}>022 49556666</p>
                      <strong style={{marginLeft:"40px",marginRight:'40px',textAlign:'center'}}>
                        Click here to chat:
                      </strong>
                      <a href='https://wa.me/+919820420530'><img  src="assets/img/wp.png" style={{width:'60px',height:'50xpx'}} class="img-fluid" alt=""/></a>
                    </div>


</div>

<div class="col-lg-3 d-flex align-items-stretch aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
  <div class="info-box" style={{backgroundColor:"#fff"}}>
    <i style={{marginLeft:'125px'}} class="bx bx-envelope"></i>
    <br/>
    <br/>
    <h2>Email Us</h2>
    <strong style={{marginLeft:"55px",marginRight:'60px',textAlign:'center'}}>
      Mayka Syntex :
    </strong>
    <br/>
    <p style={{marginRight:'60px',textAlign:'center'}}>contact@mayka.co.in
      <br/>
      samay@mayka.co.in</p>
    <br/>
    <strong style={{marginLeft:"55px",marginRight:'50px',textAlign:'center'}}>
      Mayka Lifestyle :
    </strong>
    <br/>
    <p style={{marginRight:'60px',textAlign:'center'}}>lifestyle@mayka.co.in
      <br/>
      hardik@mayka.co.in
    </p>
  </div>
</div>





 

</div>
                      <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3772.5220301549894!2d72.826338!3d18.996705000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8b1fc5937ee3e99c!2sMayka%20Syntex!5e0!3m2!1sen!2sus!4v1594286161085!5m2!1sen!2sus"
              width="100%" height="450" frameborder="0"  allowfullscreen="" aria-hidden="false"
              tabindex="0"></iframe>
                      
                    </div>

                  </div>

                  

                </div>

              </div>
            </section>
            <footer id="footer" >

                <div class="container">
                  <div class="copyright">
                    © Copyright <strong><span>Mayka</span></strong>. All Rights Reserved
                  </div>
                  <div class="credits">

                    Designed by <a href="linkedin.com/in/preet-sanghavi-981739184/"> Preet Sanghavi</a>
                  </div>
                </div>
            </footer>
          </main>
      </React.Fragment>
    );
  }
}

export default Maincontent;
