import React, { Component } from "react";

import Maincontent from "./Components/Maincontent";

import NavbarPage from './Components/Header'
import Team from './Components/OurTeam'

import { HashRouter, Route, Link,Switch, BrowserRouter } from "react-router-dom";
class App extends Component {  


  render() {
    return (<>
       {/* <NavbarPage/> */}
      <React.Fragment>
      <BrowserRouter>
                <Route exact  path="/" component = {Maincontent}/>
                <Route exact  path="/OurTeam" component = {Team}/>
                
            </BrowserRouter>
    
    
       
      </React.Fragment>
      </>
    );
  }
}

export default App;
