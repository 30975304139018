import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import './Testimonials.css'


export default class Testimonials extends Component {
  render() {
    return (
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
        <img src="/assets/img/Niyam.jpeg" />
          <div className="myCarousel">
            <h3>Niyam Haria</h3>
            
            <p>
            Finest quality of shirting fabrics from the house of reputed Textile Mills of India; Along with the commitment of timely delivery at the right time.
            </p>
          </div>
        </div>

        <div>
          <img src="/assets/img/Anubhav.jpeg" />
          <div className="myCarousel">
            <h3>Anubhav Behal</h3>
           
            <p>
            A well thought over collection of cotton fabrics for all shirting requirements. Researched prints, colors, patterns and finishes and well maintained stocks across entire collection.
            </p>
          </div>
        </div>

        <div>
        <img src="/assets/img/temp.jpg" style = {{height:'140px'}}/>
          <div className="myCarousel">
            <h3>Bobby Saini</h3>
           
            <p>
            Beautiful range and carrying huge stock inventory so we can get a lot of never out of stock items. Glad to have worked with them. Looking forward to work more with them.
      
            </p>
          </div>
        </div>

        <div>
          <img src="/assets/img/Pinky.jpeg" />
          <div className="myCarousel">
            <h3>Pinky Kotecha</h3>
           
            <p>
            Ample collection of fabric that suits my customer taste . Good quality and good service 😊😊 keep it up
            </p>
          </div>
        </div>
        <div>
          <img src="/assets/img/LastTestimonial.jpeg" />
          <div className="myCarousel">
            <h3>Hitesh Jain</h3>
           
            <p>
            Mayka Group has completely elevated my taste for shirting fabrics with a perfect blend of creativity and experience . It’s the best one stop store for the most  exquisite fabric collection from basic essentials to  statement pieces. I truly prefer them for  top quality fabrics and product satisfaction.

            </p>
          </div>
        </div>

        


      </Carousel>
    );
  }
}