import React from "react";

import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

class BootstrapCarouselComponent extends React.Component {

    render() {
        return (
            <div>
                <div className='container-fluid' >

                    <div className="row">
                        <div className="col-12">
                            <Carousel>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/img/SliderMayka.png"
                                        alt="First slide"
                                        style ={{height:'400px'}}
                                    />
                                    <Carousel.Caption>
                                      
                                        
                                    </Carousel.Caption>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/img/MaykaOffice.jpg"
                                        alt="Second slide"
                                        style ={{height:'400px'}}
                                    />

                                    <Carousel.Caption>
                                       
                                    </Carousel.Caption>
                                </Carousel.Item>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src="assets/img/MaykaShirt.jpg"
                                        alt="Third slide"
                                        style ={{height:'400px'}}
                                    />
                                    <Carousel.Caption>
                                        
                                    </Carousel.Caption>
                                </Carousel.Item>

                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        )
    };
}

export default BootstrapCarouselComponent;